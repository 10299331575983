export const GA_TRACKING_ID = "UA-203118103-1";
// google ads campaign -- I think I'm linking from Google Analytics so dont think I need both
// export const GA_TAG_ID = "AW-332197307";
const isProduction = process.env.NEXT_PUBLIC_ENV === "production";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL): void => {
  if (!isProduction || typeof window === "undefined") {
    return;
  }
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

type GTagEvent = {
  action: string;
  category?: string;
  label?: string;
  value?: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent): void => {
  if (!isProduction || typeof window === "undefined") {
    return;
  }
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value,
  });
};
