import { AnalyticsEvents } from "./constants";

const isProduction = process.env.NEXT_PUBLIC_ENV === "production";
if (process.env.NEXT_PUBLIC_ENV !== "production") {
  // eslint-disable-next-line
  console.log("current env: ", process.env.NEXT_PUBLIC_ENV);
}
/**
 * analytics wrapper to check that window exists before tracking
 * (for SSR protection)
 * @param event
 * @param params
 */
export function track(event: AnalyticsEvents, params?: any) {
  if (!isProduction || typeof window === "undefined") {
    return false;
  }
  analytics.track(event, params ? params : undefined);
}
