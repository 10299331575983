import { GetServerSidePropsContext } from "next";
import { setCookie, parseCookies } from "nookies";
import { useJwt } from "react-jwt";

/**
 * NOTE - FILE WIP - need to fix once JWTs finalized
 * @param ctx
 * @param redirectLocation
 */
function redirect(ctx: GetServerSidePropsContext, redirectLocation = "/") {
  ctx.res.setHeader("location", redirectLocation);
  ctx.res.statusCode = 302;
  ctx.res.end();
}

/**
 * TODO: Research proper handling / safety protocols
 * @param token - user jwt token
 */
export function setToken(token: string): any {
  const res = setCookie(null, "userToken", token, {
    path: "/",
    // httpOnly: true, // ERROR setting
    // secure: true,
    // sameSite: true,
  });
  return res;
}

/**
 * TODO: Research proper handling / safety protocols
 * @param trackingCookies - user tracking context
 */

export function setTracking(trackingCookies: string): any {
  const res = setCookie(null, "trackingCookies", trackingCookies, {
    path: "/",
    // httpOnly: true, // ERROR setting
    // secure: true,
    // sameSite: true,
  });

  return res;
}

/**
 * CTX - context from getServerSideProps call
 * TODO - can infer authedRoute from 'ctx.resolvedUrl' instead of passing
 * authedRoute - tells if current page is unauthed or auth
 * redirectLocation - where to go if fails, defaults homescreen
 */
export interface CheckAuthParams {
  ctx: GetServerSidePropsContext;
  authedRoute: boolean;
  redirectLocation?: string;
}

/**
 * checkAuthed - checks there is a token
 * // TODO: should also validate JWT expiration (or be done elsewhere)
 * @param options
 */
export async function checkAuthed(options: CheckAuthParams) {
  const { ctx, authedRoute, redirectLocation } = options;
  const { userToken: token } = parseCookies(ctx);
  // DESIRED ROUTE SHOULD BE AUTHED
  if (authedRoute && !token) {
    // & checkJWT(token)} ) {
    // token && destroyCookie(ctx, 'token');
    redirect(ctx, redirectLocation);
    return;
  } else if (authedRoute) {
    // Continue to route
    return token;
  }
}

/**
 * Verify token still valid against Jwt expiration
 * @param token - string token
 */
export async function checkJWT(token?: string): Promise<boolean> {
  if (!token) {
    return false;
  }
  const { isExpired } = useJwt(token);
  if (isExpired) {
    return false;
  }
  return true;
}
