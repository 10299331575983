import { Tracking, TrackingRawResponse } from "core/tracking/types";

export function decodeTracking(data: TrackingRawResponse): Tracking | null {
  try {
    return {
      action: data.action,
      funnel: data.funnel,
      fv: data.fv,
      jobRefId: data.job_ref_id,
      origin: data.origin,
      phone: data.phone,
      sessionId: data.session_id,
    };
  } catch {
    return null;
  }
}
