export enum AnalyticsEvents {
  SMALL_SCREEN_REDIRECT = "Small Screen Redirect Shown",
  EMAIL_SUBSCRIBE = "Subscribed to Emails",
  EMAIL_SUBSCRIBE_CLOSE = "Close Subscribe Popup",
  EMAIL_PROMPT_SHOWN = "Email Prompt Shown",
  CLICKED_NEWS_ARTICLE = "Clicked News Article",
  JOB_FEED_VIEWED = "Job Feed",
  NEWS_FEED_VIEWED = "News Feed",
  // Note currently cant reach this - only redirects (would be if we had internal view possible)
  NEWS_ARTICLE_VIEWED = "News Article Viewed",
  JOB_POST_VIEWED = "Job Post Viewed",
  JOB_POST_GO_BACK = "Job Post Go Back",
  UNUATH_APPLY_ATTEMPT = "Unauth Apply Attempt",
  JOB_APPLY_REDIRECT = "Job Apply Redirect",
  JOB_SEARCH_CLICKED = "Job Search Clicked",
  JOB_APPLY_BTN_CLICKED = "Job Apply Btn Clicked",

  // LOGIN
  CANCEL_SIGN_IN = "Cancel Sign In",
  CANCEL_CREATE_ACCOUNT = "Cancel Create Account",
  CANCEL_CONFIRM_IDENTITY = "Cancel Confirm Identity",
  SUBMIT_PHONE_ATTEMPT = "Submit Phone Number Attempt",
  SUBMIT_PHONE_ERROR = "Submit Phone Number Error",
  SUBMIT_SMS_ERROR = "Submit SMS Error",
  SIGNUP_SUCCESS = "Signup Success",
  LOGIN_SUCCESS = "Login Success",

  CLICKED_SWITCH_TO_CREATE = "Clicked Switch to Create",
  CLICKED_SWITCH_TO_LOGIN = "Clicked Switch to Login",

  SIGNUP_PROMPTED = "Signup Prompted",

  // DOWNLOAD BUTTONS
  DL_GOOGLE_PLAY_CLICKED = "Download On Google Play Clicked",
  DL_APP_STORE_CLICKED = "Download On App Store Clicked",
  APPLY_IN_APP_CLICKED = "Apply In App Modal Opened",
  SEND_APP_TO_PHONE_SUCCESS = "Send App Link to Phone Success",
  // NAVBAR
  NAVBAR_CLICK_POST_NEW_JOB = "Navbar Click Post New Job",
  NAVBAR_CLICK_NEWS_TAB = "Navbar Click News Tab",
  NAVBAR_CLICK_JOBS_TAB = "Navbar Click Jobs Tab",
  NAVBAR_CLICK_PROFILE_TAB = "Navbar Click Profile Tab",
  NAVBAR_CLICK_SIGN_IN = "Navbar Click Sign In",
  NAVBAR_CLICK_SIGN_OUT = "Navbar Menu Click Sign Out",
  NAVBAR_CLICK_ACCOUNT = "Navbar Menu Click Account",
  NAVBAR_CLICK_SUPPORT = "Navbar Menu Click Support",
  NAVBAR_CLICK_PRODUCTS_TAB = "Navbar Click Products Tab",
  NAVBAR_CLICK_ABOUT_US_TAB = "Navbar Click About Us Tab",
  CLICK_EMPLOYER_LOOKING = "Employer Looking Btn Clicked",
}

export enum InternalAnalyticsEvents {
  SEND_APP_TO_PHONE_CLICKED = "send_app_to_phone_btn_clicked",
  SEND_APP_TO_PHONE_SUCCESS = "send_app_to_phone_requested",
  APPLY_IN_APP_CLICKED = "apply_in_app_btn_clicked",
  SMALL_SCREEN_REDIRECT = "small_screen_redirect_shown",

  NEWS_ARTICLE_VIEWED = "news_article_clicked",
  NEWS_ARTICLE_LIKE_CLICKED = "news_article_like_btn_clicked",

  NAV_CLICK_JOB_SEEKER_SIGN_IN = "job_seeker_sign_in_btn_clicked",
  NAVBAR_CLICK_PROFILE_TAB = "profile_btn_clicked",
  NAVBAR_CLICK_JOBS_TAB = "jobs_btn_clicked",
  NAVBAR_CLICK_NEWS_TAB = "new_btn_clicked",
  NAVBAR_CLICK_PRODUCTS_TAB = "products_btn_clicked",
  NAVBAR_CLICK_ABOUT_US_TAB = "about_us_btn_clicked",
  NAVBAR_CLICK_POST_NEW_JOB = "post_job_btn_clicked",
  CLICK_EMPLOYER_LOOKING = "employer_looking_btn_clicked",

  VIEW_JOB_CLICKED_FORCED_CREATE_ACCOUNT = "web_job_see_more_btn_clicked_forced_create_account",
  VIEW_JOB_CLICKED = "web_job_see_more_btn_clicked",
  JOB_APPLY_REDIRECT = "job_apply_redirect_btn_clicked",
  JOB_APPLY_BTN_CLICKED = "web_apply_btn_clicked",
  WEBSITE_VISIT = "website_visit",
}
