import { getDeeplinkClient } from "lib/http/api";
import { useCallback, useState } from "react";
import { Tracking, TrackingRawResponse } from "./types";
import { encodeToSnakeCase } from "lib/http/formatForServer";
import { AxiosResponse } from "axios";
import { decodeTracking } from "./payload";
import { setTracking } from "lib/http/session";
import { InternalAnalyticsEvents } from "lib/analytics/constants";
import { parseCookies } from "nookies";
import { useRouter } from "next/router";
//import { CookieKeys } from "lib/types/common";

const isProduction = process.env.NEXT_PUBLIC_ENV === "production";
// useTracking posts the specified tracking event
// and stores the request response in cookies as to be used
// on subsequent requests.
// **NOTE - if the session id a session id will be generated server side
// and returned in response as to use on subsequent requests
export function useTracking(): {
  internalTrackEvent: (
    event: InternalAnalyticsEvents | string,
    params?: Partial<Tracking>
  ) => Promise<Tracking | null>;
  isTracking: boolean;
} {
  const [isTracking, setIsTracking] = useState(false);
  const router = useRouter();
  const path = router.pathname;

  const internalTrackEvent = useCallback(
    async (
      event: InternalAnalyticsEvents | string,
      params?: Partial<Tracking>
    ): Promise<Tracking | null> => {
      if (!isProduction) {
        // eslint-disable-next-line
        console.warn("Note: tracking disabled on local dev: ", event);
        return null;
      }
      setIsTracking(true);
      const formattedBody = {
        action: event,
        ...params,
      };

      const cookies = parseCookies();
      const { trackingCookies } = cookies;
      let etc: Tracking | null;
      if (trackingCookies) {
        etc = JSON.parse(trackingCookies);
      } else {
        etc = null;
      }

      const mergedBody = {
        ...formattedBody,
        funnel: router.query["funnel"]?.toString(),
        fv: router.query["fv"]?.toString(),
        jobRefId:
          formattedBody.jobRefId || router.query["job_ref_id"]?.toString(),
        origin: router.query["origin"]?.toString(),
        path: path,
        sessionId: router.query["session_id"]?.toString() || etc?.sessionId,
      };

      const body = encodeToSnakeCase(mergedBody);

      return getDeeplinkClient(undefined, true)
        .post(`web/tracking`, body)
        .then((res: AxiosResponse<TrackingRawResponse>) => {
          if (res.status == 500) {
            console.warn("500");
            return null;
          }

          const resRaw = res.data;
          const formattedRes = decodeTracking(resRaw);
          if (!formattedRes) {
            console.warn("Error unpacking data");
            return null;
          }

          const dataStr = JSON.stringify(formattedRes);
          setTracking(dataStr);
          return formattedRes;
        })
        .catch((e: any) => {
          console.warn("failed to track event error: ", e);
          return null;
        })
        .finally(() => {
          setIsTracking(false);
          return null;
        });
    },
    [setTracking]
  );

  // Get initial Context on mount
  //useEffect(() => {
  //
  //}, [router, parseCookies]);

  return {
    internalTrackEvent,
    isTracking,
  };
}
